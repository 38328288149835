<template>
  <v-form ref="form" v-model="isFormValid" lazy-validation>
    <v-card flat elevation="7" style="padding-bottom: 60px;">
      <v-row class="mx-1 mb-4 pb-4 striped k-border-bottom">
        <v-col cols="6" sm="12" class="px-4 py-4">
          <h3>{{ $t('documentCustomer') }}: {{ getShipmentDocumentCustomer() }}</h3>
          <h4 class="mt-3">{{ $t('recipient') }}:</h4>
          <p class="text-h5">
            <label>{{ recipient.recipientBusinessName }}</label><br/>
            {{ recipient.address }} {{recipient.zipCode}} {{recipient.city}} {{recipient.province}} {{recipient.country}}
          </p>
        </v-col>
        <v-col cols="12" sm="2" class="px-4">
          <v-text-field v-model="recipient.phone" :label="$t('phone')" hide-details="auto" type="text" class=""></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field v-model="recipient.contactName" :label="$t('contactName')" hide-details="auto" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" sm="3">
          <v-text-field v-model="recipient.email" :label="$t('email', {r:''})" hide-details="auto" type="text" :rules="emailRules"></v-text-field>
        </v-col>
        <v-col cols="12" sm="5">
          <v-checkbox v-model="shipment.printInstrDocs" :label="$t('printInstrDocs')" hide-details="auto"></v-checkbox>
        </v-col>
      </v-row>
      <v-row class="px-6 py-3">
        <v-col cols="12" sm="4" class="mb-0 pb-0">
          <v-autocomplete ref="sendersAc" v-model="shipment.senderId" :items="dropdownSenders" hide-details="auto" :label="$t('sender')" required :rules="selectionRules"
            @focus="validateField"></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field v-model="shipmentDate" :label="$t('shipmentDate')" required hide-details="auto" type="date"></v-text-field>
        </v-col>
        <v-col cols="12" sm="4">
          <v-text-field v-model="shipment.customerReference" :label="$t('customerReference')" hide-details="auto" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" sm="2">
          <v-text-field v-model="shipment.codValue" :label="$t('codValue')" hide-details="auto" type="text"
              :rules="[v => !v || /^\d*\.?\d*$/.test(v) || $t('enterValidDecimalNumber')]"></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="mt-0 pt-0">
          <span v-if="!shipment.senderId" v-t="'pickASender'"></span>
          <span v-else><i>{{ getInfo("contact") }} | ({{ $t('tel') }}: {{ getInfo("phone") }})</i></span>
        </v-col>
        <v-col cols="12">
          <v-text-field v-model="shipment.specialInstructions" :label="$t('specialInstructions')" required hide-details="auto" type="text"></v-text-field>
        </v-col>
        <v-col cols="12" class="pt-6 mt-3" style="background:#eee;">
          <h4 v-t="'selectShippingOption'" v-show="hasServices"></h4>

          <p style="margin-bottom: 8px;" v-if="servicesLoading">
            <i>{{ $t('servicesLoading') }}</i>
          </p>

          <v-radio-group v-model="selectedShippingProduct" v-if="hasServices && !servicesLoading">
            <v-radio class="mt-2"
                     v-for="(service, i) in servicesList"
                     :key="i"
                     :label="`${service.description} - ${service.price} € - ${service.timing}`"
                     :value="service.code"
            ></v-radio>
          </v-radio-group>

          <template v-else-if="!servicesLoading">
            <h5 class="k-color-secondary" style="margin-bottom: 8px;">
              <i>{{ $t('noServicesAvailable') }}</i>
            </h5>
            <p class="k-color-secondary" style="margin-bottom: 8px;">
              <i>{{ $t('noServicesAvailableErrorIntro') }} {{ incompletePriceCheckerMessage || $t("noServicesAvailableErrorNotSpecified") }}</i>
            </p>
          </template>
        </v-col>
      </v-row>
      <v-row class="px-6 pt-6">
        <v-col cols="12" v-for="(doc) in shipment.documents" :key="'doc'+doc.id">
          <h4>{{ $t('packageDetailsOf') }} {{ doc.type }}-{{doc.code}}{{doc.bis.trim() === ""? "" : "/" + doc.bis}}</h4> <span>{{doc.impReferences}}</span>
          <v-row class="px-1 mt-2 mb-1" v-for="(pack, idx) in doc.packages" :key="'doc'+doc.id+'pack'+idx">
            <v-col cols="12" sm="12" class="mb-0 pb-0">
              <v-row class="py-0" >
                <v-col cols="12" sm="2">
                  <v-text-field v-model="pack.dimLength" :label="$t('lengthCm')" required hide-details="auto" type="text" outlined dense
                      :rules="[v => !!v || $t('lengthRequired'), v => /^\d*\.?\d*$/.test(v) || $t('enterValidDecimalNumber')]"
                  @blur="getPackageTotals"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field v-model="pack.dimWidth" :label="$t('widthCm')" required hide-details="auto" type="text" outlined dense
                                :rules="[v => !!v || $t('widthRequired'), v => /^\d*\.?\d*$/.test(v) || $t('enterValidDecimalNumber')]"
                  @blur="getPackageTotals"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field v-model="pack.dimHeight" :label="$t('heightCm')" required hide-details="auto" type="text" outlined dense
                      :rules="[v => !!v || $t('heightRequired'), v => /^\d*\.?\d*$/.test(v) || $t('enterValidDecimalNumber')]"
                  @blur="getPackageTotals"></v-text-field>
                </v-col>
                <v-col cols="12" sm="2">
                  <v-text-field v-model="pack.dimWeight" :label="$t('weightKg')" required hide-details="auto" type="text" outlined dense
                      :rules="[v => !!v || $t('weightRequired'), v => /^\d*\.?\d*$/.test(v) || $t('enterValidDecimalNumber')]"
                  @blur="getPackageTotals"></v-text-field>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-text-field v-model="pack.contentDescription" :label="$t('contentDescription')" required hide-details="auto" type="text" outlined dense
                      :rules="[v => !!v || $t('contentDescriptionRequired')]"></v-text-field>
                </v-col>
                <v-col cols="12" sm="1">
                  <v-btn block @click="removePackage(doc, idx)" :disabled="shipment.id > 0">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-1 mt-0 mb-3" v-if="doc.packages == null || doc.packages.length === 0">
            <v-col cols="12">
              <p><i v-t="'noPackagesFound'"></i></p>
              <v-btn class="mt-1" block outlined @click="addPackage(doc)" :disabled="shipment.id > 0">
                <v-icon class="mr-1">mdi-plus</v-icon>
                {{ $t('addPackage')}}
              </v-btn>
            </v-col>
          </v-row>
          <v-row  class="px-1 mt-0 mb-3" v-else>
            <v-col cols="12">
              <v-btn block outlined @click="addPackage(doc)" :disabled="shipment.id > 0">
                <v-icon class="mr-1">mdi-plus</v-icon>
                {{ $t('addPackage')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="my-5 px-6 py-3">
        <v-col cols="12" sm="12" style="display: flex; flex-direction: column; align-items: end;">
          <h3>{{ this.packageNo }} {{ this.packageLabel }} - {{ $t('totalWeight') }}: {{ getWeight() | formatted }} Kg</h3>
        </v-col>
      </v-row>
      <v-speed-dial bottom right fixed>
        <template v-slot:activator>
          <v-btn class="mx-2" color="info" elevation="24" fab x-large @click="print('')">
            <v-icon>mdi-printer</v-icon>
          </v-btn>
          <v-btn class="mx-2" color="secondary" elevation="24" fab x-large @click="print('T')">
            <div style="height: 30px; width: 30px; object-fit: contain; object-position: center;">
              <v-img src="../../assets/zebra.png"></v-img>
            </div>
          </v-btn>
        </template>
      </v-speed-dial>
    </v-card>
  </v-form>

</template>

<script>
import {call, get, sync} from "vuex-pathify";
import moment from "moment-timezone";

export default {
  name: "TntShipment",
  data: () => ({
    isFormValid: false,
    packageNo: 0,
    packageLabel: "",
    shipmentDate: null,
    servicesList: [],
    selectedShippingProduct: "",
    incompletePriceCheckerMessage: "",
    servicesLoading: true
  }),
  computed: {
    dropdownSenders: get("tnt/dropdownSenders"),
    senders: get("tnt/senders"),
    shipment: sync("tnt/shipment"),
    recipient: sync("tnt/recipient"),
    emailRules(){
      return [
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(v) || this.$t('invalidEmail')
      ]
    },
    selectionRules(){
      return [(v) => !!v || this.$t('selectionIsRequired')]
    },
    hasServices() {
      return this.servicesList.length > 0;
    },
    noServicesAvailableMessage() {
      return this.incompletePriceCheckerMessage || this.$t("noServicesAvailableErrorNotSpecified");
    }
  },
  mounted() {
    this.$nextTick(() => {
      const firstInput = this.$refs.sendersAc;
      if (firstInput) {
        firstInput.focus();
        this.validateField();
      }
    });
  },
  watch: {
    'shipment.codValue'(newValue) {
      if (newValue === '') {
        this.shipment.codValue = null;
      }
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    moment,
    initialize: function () {
      this.isFormValid = false;
      this.packageNo = 0;
      this.packageLabel = this.$t('packages');
      this.shipmentDate = this.shipmentDateFormatted(this.shipment.shipmentDate);
      this.servicesList = [];
      this.selectedShippingProduct = "";
      this.incompletePriceCheckerMessage = "";
      this.servicesLoading = true;

      if(this.shipment.id === undefined || !this.shipment.documents || this.shipment.documents.length === 0) {
        this.showError(this.$t('selectionIsEmpty'));
        this.$router.push("/tnt");
      }

      this.recipient = this.shipment.documents.at(0);

      this.cleanUpEmail(); //Per evitare che la pagina si blocchi per la validazione di indirizzi molto lunghi e errati

      if(this.shipment.shippingProduct && this.shipment.shippingProduct.trim() !== "")
        this.selectedShippingProduct = this.shipment.shippingProduct;

      this.getPackageTotals();
    },
    cleanUpEmail() {
      if (this.recipient.email && !/@\w+\.\w{2,}$/.test(this.recipient.email)) {
        this.recipient.email = ''; // Svuota l'indirizzo email se non ha un dominio valido
      }
    },
    checkShippingCosts() {

      this.formatShipmentDates();

      this.apiPriceCheckerPost(this.shipment)
          .then((res) => {

            if (res.complete != null) {
              if (res.complete.servicesList && res.complete.servicesList.length > 0) {

                this.servicesList = res.complete.servicesList;

                if(this.shipment.id === 0)
                  this.selectedShippingProduct = this.servicesList.reduce((min, current) => {
                    return parseFloat(current.price.replace(',', '.')) < parseFloat(min.price.replace(',', '.')) ? current : min;
                  })?.code;
                else this.selectedShippingProduct = this.shipment.shippingProduct;

              }
            }
            else if(res.incomplete != null) {

              this.incompletePriceCheckerMessage = res.incomplete.iMessage;
            }

            this.servicesLoading = false;

            this.$forceUpdate();
          })
          .catch((error) => {
            this.showError(error);

            this.servicesLoading = false;
            this.$forceUpdate();
          });
    },
    formatShipmentDates() {
      this.shipment.shipmentDate = this.shipmentDateFormatted(this.shipmentDate);
      this.shipment.documents = this.shipment.documents.map(document => {
        document.documentDate = moment(document.documentDate).format("YYYY-MM-DD");
        return document;
      });
    },
    validateField() {
      this.$refs.form.validate();
    },
    validateBeforePrinting() {

      if(this.shipment.senderId == null) {
        this.showError(this.$t('selectSender'));
        return false;
      }

      let hasPackages = this.shipment.documents.some(document =>
          document.packages && document.packages.length > 0
      );

      if(!hasPackages) {
        this.showError(this.$t('noPackages'));
        return false;
      }

      return true;
    },
    print(mode) {

      if(!this.isFormValid) return;

      if(!this.validateBeforePrinting()) return;

      this.shipment.labelFormat = mode;

      this.formatShipmentDates();

      this.shipment.shippingProduct = this.selectedShippingProduct;

      let selectedService = this.servicesList.filter(item => item.code === this.shipment.shippingProduct).at(0);

      if(selectedService)
        this.shipment.extimatedShippingCost = selectedService.price.replace(",", ".");

      if(this.shipment.id === 0) this.shipment.shipmentAction = "I"; else this.shipment.shipmentAction = "M";

      this.apiShipmentsHandleShipmentWithLabelsPost(this.shipment)
      .then((res) => {
        this.downloadPdf({
          filename: `${this.$moment().format('YYYYMMDD')}-${this.shipment.documents.reduce((a, b) => { a.push(b.code); return a; }, []).join('-')}`,
          content: res
        });
        this.$router.push("/tnt");
      }).catch((error) => {
          this.$router.push("/tnt");
      });
    },
    getShipmentDocumentCustomer() {
      let firstDoc = this.shipment.documents.at(0);

      return `${firstDoc.customerCode} - ${firstDoc.customerBusinessName}`;
    },
    shipmentDateFormatted(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    getPackageTotals() {
      this.packageNo = this.shipment.documents.reduce((packageNo, doc) => {
        return packageNo + (doc.packages == null? 0 : doc.packages.length);
      }, 0);

      this.packageLabel = (this.packageNo === 0 || this.packageNo > 1? this.$t('packages') : this.$t('package'));

      this.checkShippingCosts();
    },
    getWeight(){
      return this.shipment.documents.reduce((totalWeight, doc) => {
        return totalWeight + (doc.packages == null? 0 : doc.packages.reduce((docWeight, pack) => {
          return docWeight + Number(pack.dimWeight ?? 0);
        }, 0));
      }, 0);
    },
    getInfo(param) {
      const selectedSender = this.senders.find(
          (el) => el.id == this.shipment.senderId,
      );

      switch (param) {
        case "address":
          return `${selectedSender?.address} - ${selectedSender?.zipCode} ${selectedSender?.town} (${selectedSender?.province})`;
        case "contact":
          return `${selectedSender?.contactName ?? this.$t('missingContactName')}`;
        case "phone":
          return `${selectedSender?.phone ?? this.$t('missingPhone')}`;
        /*case "email":
          return `${selectedSender?.email ?? this.$t('missingEmail')}`;*/
        default:
          return "";
      }
    },
    removePackage(doc, index = -1){
      if (index > -1) doc.packages.splice(index, 1);
      if(doc.packages.length === 0) doc.packages = null;

      this.getPackageTotals();
    },
    addPackage(doc){
      if(doc.packages == null) doc.packages = [];
      doc.packages.push({});

      this.getPackageTotals();
    },
    ...call("app/*"),
    ...call("tnt/*"),
    ...call("utils/*"),
  }
}
</script>

<style>
.striped {
  background: repeating-linear-gradient( 135deg, #eeeeee, #ffffff 10px, #eeeeee 10px, #ffffff 20px );
}
</style>

